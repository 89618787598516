

































































































import Vue from "vue";
import {
  RepositoryClient,
  ApiConfiguration,
  ClientAgreementSearchCriteria,
  CargoType,
  UserConfigurationType,
  RepositoryDto
} from "@/scripts/cld.api";
import { cargoTypeName } from "@/scripts/misc/enumNames";
import translatedDropdown from "@/scripts/misc/translatedDropdown";
import { actions, gets } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import cached from "@/scripts/misc/cached";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import SwitchDouble from "@/components/shared/input/SwitchDouble.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Autocomplete,
    Dropdown,
    SwitchDouble,
    InfoTooltip
  },
  props: {
    type: String,
    cargoType: Number as () => CargoType,
    exportDisabled: Boolean,
    exportLoading: Boolean
  },
  watch: {
    cargoType: {
      handler() {
        this.searchCriteria.date = new MomentX();
        this.autocomplete.truckTypes = truckTypesByCargoType(this.cargoType);
        cached("suppliers", [this.cargoType, true, true])
          .then(res => {
            this.autocomplete.suppliers = res;
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      let title = "";
      if (this.type === this.types.agreements) {
        title += this.$t("AgreementList").toString();
      } else {
        title += this.$t("ProcurementList").toString();
      }
      title += " - " + cargoTypeName(this.cargoType);
      return title;
    },
    searchCriteria(): ClientAgreementSearchCriteria {
      let type =
        this.type === this.types.agreements
          ? UserConfigurationType.ClientAgreement
          : UserConfigurationType.ClientProcurement;
      return this.$store.getters[gets.userConf](type, this.cargoType);
    },
    priceSwitchEnabled(): boolean {
      return this.cargoType === CargoType.Road;
    },
    dateTooltip(): string {
      let text = this.$t("AgreementDateSearchExplanation1").toString();
      if (this.isRoad) {
        text += " " + this.$t("AgreementDateSearchExplanation2").toString();
      }
      return text;
    },
    overviewSwitchValue: {
      get(): boolean {
        return this.type === this.types.procurementOverview;
      },
      set() {
        if (this.overviewSwitchValue) {
          this.$router.push({
            path: "/procurements",
            query: { ...this.$route.query }
          });
        } else {
          this.$router.push({
            path: "/procurement-overview",
            query: { ...this.$route.query }
          });
        }
      }
    },
    unitBasePriceDate: {
      get(): string {
        return this.searchCriteria.unitBasePriceDate.datePrint();
      },
      set(s: string) {
        this.searchCriteria.unitBasePriceDate = new MomentX(s);
      }
    },
    isRoad(): boolean {
      return this.cargoType === CargoType.Road;
    },
    priceLabel(): string {
      if (this.type === this.types.agreements) {
        return t("AgreedPrice");
      }
      return t("OfferedPrice");
    }
  },
  methods: {
    search() {
      if (this.searchCriteria.date !== null) {
        this.$emit("search");
      }
    },
    clearSelection() {
      this.$emit("clearSelection");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    fetchIndexPeriods() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .baseIndexPeriods()
        .then(res => {
          this.baseIndexDates = res
            .map(
              i =>
                new RepositoryDto({
                  id: i.indexDate.datePrint() as any,
                  text: i.displayName
                })
            )
            .sort((a, b) => -stringCompare(a.id, b.id));
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPriceFilters() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .priceFilters()
        .then(res => {
          this.autocomplete.priceFilters = translatedDropdown(res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchIndexPeriods();
    if (this.type === this.types.procurements) {
      this.fetchPriceFilters();
    }
  },
  data: (): {
    types: {
      agreements: string;
      procurements: string;
      procurementOverview: string;
    };
    baseIndexDates: RepositoryDto[];
    autocomplete: {
      priceFilters: RepositoryDto[];
      suppliers: RepositoryDto[];
      truckTypes: RepositoryDto[];
    };
  } => ({
    types: {
      agreements: "agreements",
      procurements: "procurements",
      procurementOverview: "procurement-overview"
    },
    baseIndexDates: [],
    autocomplete: {
      priceFilters: [],
      suppliers: [],
      truckTypes: []
    }
  })
});
